<template>
	<div id="body">
		<div class="fatherBox">
			<div class="header">
				<div class="headerBox">
					<span class="stutitle"><span style="color:#cbcb42;cursor: pointer;"
							@click="toStulist()">学生管理</span>/{{ stuData.StuName }}</span>
					<div style="float:right;font-size:18px;color:#409EFF;cursor: pointer;" @click="$router.back()">返回
					</div>
				</div>
			</div>
			<div class="stubody" style="margin:25px 40px 10px 10px;">
				<div style="    float: right;padding: 10px;    margin-top: -21px;" v-if="activeName == 'second'">
					<el-button v-if="dstatus == true" type="primary" plain @click="upStuEyeDetail()">更新信息</el-button>
					<el-button v-if="dstatus == true" type="primary" @click="(dialogVisible = true)">导出数据</el-button>
					<el-button v-if="dstatus == true" type="primary" @click="doExportWord()">查看详细报告</el-button>
					<el-button v-if="dstatus != true" type="primary" plain @click="upStuDetail()">修改信息</el-button>
				</div>
				<div style="    float: right;padding: 10px;    margin-top: -21px;" v-if="activeName == 'four'">
					<el-button v-if="dstatus == true" type="primary" plain @click="openAddDialog">添加</el-button>
					<el-button v-if="dstatus == true" type="primary" @click="(dialogVisible = true)">导出数据</el-button>
				</div>
				<div style="text-align: left;width:93%">

					<template>
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="学生基础信息" name="first">
								<div style="width: 100%;">
									<div class="tt">学生信息</div>

									<table border="0px" cellspacing="" cellpadding=""
										style="width: 60%;text-align: left;padding-bottom: 80px;margin: 10px;">
										<tr>
											<td><span class="tabtit">姓名：</span>{{ stuData.StuName }}</td>
											<td><span class="tabtit">性别：</span>{{ stuData.StuGender == 1 ? '男' :
												'女' }}</span>
											</td>
										</tr>
										<tr>
											<td><span class="tabtit">班级：</span>{{ stuData.TypeName + stuData.ClassName
											}}
											</td>
											<td><span class="tabtit">学号：</span>{{ stuData.stu_id }}</td>
										</tr>
										<tr>
											<td><span class="tabtit">出生年月：</span>{{ stuData.BirthdayDate }}</td>
											<td><span class="tabtit">学生编号：</span>{{ stuData.StuNumber }}</td>
										</tr>
										<tr>
											<td><span class="tabtit">短编号：</span>{{ stuData.short_num }}</td>
											<td><span class="tabtit">图像认证：</span>
												<img :src="stuData.ImageUrl" alt=""
													style="  position: absolute;width: 100px;">
											</td>
										</tr>
										<tr>
											<td><span class="tabtit">加入时间：</span>{{ stuData.insertTime }}</td>
										</tr>
									</table>
								</div>
								<div style="text-align: left;border-bottom: 1px solid #e4e7ed;">
									<div class="tt">家长信息</div>
									<div>
										<el-table :data="guaData" :cell-style="{ fontSize: '15px', color: '#000' }"
											:header-cell-style="{ fontSize: '20px', color: '#484848', fontWeight: '500' }"
											v-loading="loading">
											<!-- <el-table-column type="index" label="" align="center" width="100" :formatter="GuaNum"></el-table-column> -->

											<el-table-column prop="RoleName" label="家长关系" width="100" align="center">
											</el-table-column>

											<el-table-column prop="authname" label="家长昵称" width="100"
												align="center"></el-table-column>
											<el-table-column prop="RegisterMobile" label="联系电话" width="150"
												align="center"></el-table-column>
											<!-- <el-table-column prop="RoleName" label="与学生关系" align="center"></el-table-column> -->
											<el-table-column label="家长头像" width="240" align="center">
												<template slot-scope="scope">
													<el-image v-if="scope.row.facepic"
														style="width: 160px; height: 90px" fit="contain" alt="认证照片"
														:src="scope.row.facepic"
														:preview-src-list="[scope.row.facepic]"></el-image>
													<span v-else>未认证</span>
												</template>
											</el-table-column>
											<el-table-column prop="createtime" width="250" label="关联时间"
												align="center"></el-table-column>
										</el-table>
									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="视力档案" name="second">
								<div style="width: 80%; margin: auto; margin-left: 80px;">
									<el-form label-width="120px" class="demo-ruleForm">
										<el-form-item label="裸眼视力：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.Leye }}
													<span v-if="stu_list.eyetime" style="font-size: 10px;">({{
														stu_list.eyetime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.Reye }}
													<span v-if="stu_list.eyetime" style="font-size: 10px;">({{
														stu_list.eyetime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.eyetime" size="mini"
													@click="detail(1)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="球      镜(S)：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.zqj }}
													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.yqj }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(2)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="柱      镜(C)：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.zzj }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.yzj }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(3)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="散光轴位(A)：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.zzw }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.yzw }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(4)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="水平子午线曲率MM1：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.zmm1 }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.ymm1 }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(5)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="垂直子午线曲率MM2：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.zmm2 }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.ymm2 }}
													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(6)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="水平子午线角度A1：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.za1 }}
													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label="右眼：">
													{{ stu_list.ya1 }}
													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(7)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="瞳       距PD：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.tongj }}
													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(8)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="镜 眼 距VD：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.jyj }}

													<span v-if="stu_list.ygytime" style="font-size: 10px;">({{
														stu_list.ygytime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ygytime" size="mini"
													@click="detail(9)">详情</el-button>
											</el-col>
										</el-form-item>

										<el-form-item label="worth4融像：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.worth_image }}
													<span v-if="stu_list.worth_imagetime" style="font-size: 10px;">({{
														stu_list.worth_imagetime
													}})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.worth_imagetime" size="mini"
													@click="detail(20)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="远融像：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.far_image }}
													<span v-if="stu_list.far_imagetime" style="font-size: 10px;">({{
														stu_list.far_imagetime
													}})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.far_imagetime" size="mini"
													@click="detail(22)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="近融像：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.near_image }}
													<span v-if="stu_list.near_imagetime" style="font-size: 10px;">({{
														stu_list.near_imagetime
													}})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.near_imagetime" size="mini"
													@click="detail(23)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="远用眼位：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.yyyw }}
													<span v-if="stu_list.yyywtime" style="font-size: 10px;">({{
														stu_list.yyywtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.yyywtime" size="mini"
													@click="detail(19)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="近用眼位：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.jyyw }}
													<span v-if="stu_list.jyywtime" style="font-size: 10px;">({{
														stu_list.jyywtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.jyywtime" size="mini"
													@click="detail(18)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="集合近点（NPC）：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.jhjd }}
													<span v-if="stu_list.jhjdtime" style="font-size: 10px;">({{
														stu_list.jhjdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.jhjdtime" size="mini"
													@click="detail(17)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="AC/A：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.ac_a }}
													<span v-if="stu_list.ac_atime" style="font-size: 10px;">({{
														stu_list.ac_atime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ac_atime" size="mini"
													@click="detail(21)">详情</el-button>
											</el-col>
										</el-form-item>

										<el-form-item label="同时视：">
											<el-col :span="8">
												<el-form-item>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
														stu_list.l_tss }}
													<span v-if="stu_list.l_tsstime" style="font-size: 10px;">({{
														stu_list.l_tsstime }})</span>
												</el-form-item>
											</el-col>
										</el-form-item>

										<!--  <el-form-item label="同时视：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.l_tss }}
                          <span v-if="stu_list.l_tsstime" style="font-size: 10px;">({{stu_list.l_tsstime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label=" 右眼：">
                          {{ stu_list.r_tss }}
                          <span v-if="stu_list.r_tsstime" style="font-size: 10px;">({{stu_list.r_tsstime}})</span>
                        </el-form-item>
                      </el-col>
                     <el-col :span="8">
                        <el-button v-if="stu_list.l_tsstime" size="mini" @click="detail(24)">详情</el-button>
                      </el-col>
                    </el-form-item> -->

										<el-form-item label="融合视：">
											<el-col :span="8">
												<el-form-item>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
														stu_list.l_rhs }}
													<span v-if="stu_list.l_rhstime" style="font-size: 10px;">({{
														stu_list.l_rhstime }})</span>
												</el-form-item>
											</el-col>
										</el-form-item>
										<!--
                    <el-form-item label="融合视：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.l_rhs }}
                          <span v-if="stu_list.l_rhstime" style="font-size: 10px;">({{stu_list.l_rhstime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label=" 右眼：">
                          {{ stu_list.r_rhs }}
                          <span v-if="stu_list.r_rhstime" style="font-size: 10px;">({{stu_list.r_rhstime}})</span>
                        </el-form-item>
                      </el-col>
                     <el-col :span="8">
                        <el-button v-if="stu_list.l_rhstime" size="mini" @click="detail(25)">详情</el-button>
                      </el-col>
                    </el-form-item> -->

										<el-form-item label="立体视觉：">
											<el-col :span="8">
												<el-form-item>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
														stu_list.ltsj }}
													<span v-if="stu_list.ltsjtime" style="font-size: 10px;">({{
														stu_list.ltsjtime }})</span>
												</el-form-item>
											</el-col>
										</el-form-item>

										<el-form-item label="斜视检查：">
											<el-col :span="8">
												<el-form-item>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
														stu_list.l_xsjc }}
													<span v-if="stu_list.l_xsjctime" style="font-size: 10px;">({{
														stu_list.l_xsjctime }})</span>
												</el-form-item>
											</el-col>
										</el-form-item>

										<!--                    <el-form-item label="斜视检查：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.l_xsjc }}
                          <span v-if="stu_list.l_xsjctime" style="font-size: 10px;">({{stu_list.l_xsjctime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label=" 右眼：">
                          {{ stu_list.r_xsjc }}
                          <span v-if="stu_list.r_xsjctime" style="font-size: 10px;">({{stu_list.r_xsjctime}})</span>
                        </el-form-item>
                      </el-col>
                     <el-col :span="8">
                        <el-button v-if="stu_list.l_xsjctime" size="mini" @click="detail(26)">详情</el-button>
                      </el-col>
                    </el-form-item> -->

										<el-form-item label="眼轴长度：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.l_yzcd }}
													<span v-if="stu_list.l_yzcdtime" style="font-size: 10px;">({{
														stu_list.l_yzcdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.r_yzcd }}
													<span v-if="stu_list.r_yzcdtime" style="font-size: 10px;">({{
														stu_list.r_yzcdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.l_yzcdtime" size="mini"
													@click="detail(27)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="角膜厚度：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.l_jmhd }}
													<span v-if="stu_list.l_jmhdtime" style="font-size: 10px;">({{
														stu_list.l_jmhdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.r_jmhd }}
													<span v-if="stu_list.r_jmhdtime" style="font-size: 10px;">({{
														stu_list.r_jmhdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.l_jmhdtime" size="mini"
													@click="detail(28)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="前房厚度：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.l_qfhd }}
													<span v-if="stu_list.l_qfhdtime" style="font-size: 10px;">({{
														stu_list.l_qfhdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.r_qfhd }}
													<span v-if="stu_list.r_qfhdtime" style="font-size: 10px;">({{
														stu_list.r_qfhdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.l_qfhdtime" size="mini"
													@click="detail(29)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="晶体厚度：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.l_jthd }}
													<span v-if="stu_list.l_jthdtime" style="font-size: 10px;">({{
														stu_list.l_jthdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.r_jthd }}
													<span v-if="stu_list.r_jthdtime" style="font-size: 10px;">({{
														stu_list.r_jthdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.l_jthdtime" size="mini"
													@click="detail(30)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="玻璃体厚度：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.l_blthd }}
													<span v-if="stu_list.l_blthdtime" style="font-size: 10px;">({{
														stu_list.l_blthdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.r_blthd }}
													<span v-if="stu_list.r_blthdtime" style="font-size: 10px;">({{
														stu_list.r_blthdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.l_blthdtime" size="mini"
													@click="detail(31)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="角膜曲率：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.l_jmql }}
													<span v-if="stu_list.l_jmqltime" style="font-size: 10px;">({{
														stu_list.l_jmqltime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.r_jmql }}
													<span v-if="stu_list.r_jmqltime" style="font-size: 10px;">({{
														stu_list.r_jmqltime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.l_jmqltime" size="mini"
													@click="detail(32)">详情</el-button>
											</el-col>
										</el-form-item>

										<el-form-item label="正相对调节：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.zxdtj }}
													<span v-if="stu_list.zxdtjtime" style="font-size: 10px;">({{
														stu_list.zxdtjtime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.zxdtjtime" size="mini"
													@click="detail(10)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="负相对调节：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.fxdtj }}
													<span v-if="stu_list.fxdtjtime" style="font-size: 10px;">({{
														stu_list.fxdtjtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.fxdtjtime" size="mini"
													@click="detail(11)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="调节灵敏度：">
											<el-col :span="8">
												<el-form-item label="单眼：">
													{{ stu_list.zlmd }}
													<span v-if="stu_list.zlmdtime" style="font-size: 10px;">({{
														stu_list.zlmdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 双眼：">
													{{ stu_list.ylmd }}

													<span v-if="stu_list.ylmdtime" style="font-size: 10px;">({{
														stu_list.ylmdtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ylmdtime" size="mini"
													@click="detail(12)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="眼压：">
											<el-col :span="8">
												<el-form-item label="左眼：">
													{{ stu_list.z_yany }}
													<span v-if="stu_list.z_yanytime" style="font-size: 10px;">({{
														stu_list.z_yanytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 右眼：">
													{{ stu_list.y_yany }}
													<span v-if="stu_list.y_yanytime" style="font-size: 10px;">({{
														stu_list.y_yanytime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.z_yanytime" size="mini"
													@click="detail(13)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="雾视眼光：">
											<el-col :span="8">
												<el-form-item label="雾视：">
													{{ stu_list.zwsyg }}
													<span v-if="stu_list.zwsygtime" style="font-size: 10px;">({{
														stu_list.zwsygtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-form-item label=" 不雾视：">
													{{ stu_list.ywsyg }}

													<span v-if="stu_list.ywsygtime" style="font-size: 10px;">({{
														stu_list.ywsygtime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.ywsygtime" size="mini"
													@click="detail(14)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="BCC：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.bcc }}
													<span v-if="stu_list.bcctime" style="font-size: 10px;">({{
														stu_list.bcctime }})</span>
												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.bcctime" size="mini"
													@click="detail(16)">详情</el-button>
											</el-col>
										</el-form-item>
										<el-form-item label="AMP调节幅度：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.apmtj }}
													<span v-if="stu_list.apmtjtime" style="font-size: 10px;">({{
														stu_list.apmtjtime }})</span>

												</el-form-item>
											</el-col>
											<el-col :span="8">
												<el-button v-if="stu_list.apmtjtime" size="mini"
													@click="detail(15)">详情</el-button>
											</el-col>
										</el-form-item>

										<el-form-item label="色盲：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.colorblind }}
													<span v-if="stu_list.colorblindtime" style="font-size: 10px;">({{
														stu_list.colorblindtime
													}})</span>
													{{ stu_list.colorblindstatus }}
												</el-form-item>
											</el-col>

										</el-form-item>
										<el-form-item label="色弱：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.colorweak }}
													<span v-if="stu_list.colorweaktime" style="font-size: 10px;">({{
														stu_list.colorweaktime
													}})</span>
												</el-form-item>
											</el-col>

										</el-form-item>
										<el-form-item label="夜盲：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.nightblind }}
													<span v-if="stu_list.nightblindtime" style="font-size: 10px;">({{
														stu_list.nightblindtime
													}})</span>

												</el-form-item>
											</el-col>

										</el-form-item>
										<el-form-item label="视野：">
											<el-col :span="8">
												<el-form-item>
													{{ stu_list.eyerange }}

													<span v-if="stu_list.eyerangetime" style="font-size: 10px;">({{
														stu_list.eyerangetime }})</span>

												</el-form-item>
											</el-col>
										</el-form-item>
									</el-form>
								</div>

							</el-tab-pane>
							<el-tab-pane label="入离校记录" name="three">
								<div style="width: 100%;">
									<el-form label-width="80px" class="demo-ruleForm">
										<el-form-item label="时间">
											<el-date-picker @change="onChangeDate" v-model="daterange1"
												value-format="yyyy-MM-dd" type="daterange" range-separator="至"
												start-placeholder="开始日期" end-placeholder="结束日期">
											</el-date-picker>
										</el-form-item>
									</el-form>
									<div v-for="item in list" :key="item.id" style="margin-bottom:20px;">
										<div style="text-align:center;margin-bottom:20px;">{{ item.createtime }}
											检测地点：{{ item.position }}</div>
										<div v-for="pic, index in item.photostudent" :key="index"
											style="text-align:center;">
											<img :src="pic" />
										</div>
									</div>
									<div style="text-align:center">
										<el-pagination @current-change="onChangePage" layout="prev, pager, next"
											:page-count="listCount">
										</el-pagination>
									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="健康档案" name="four">
								<el-table :data="stu_health.list" v-loading="loading" style="margin-top: 31px;">
									<el-table-column label="学号" width="auto" align="center">
										<template slot-scope="scope">
											{{ stu_health.student.stu_id }}
										</template>
									</el-table-column>
									<el-table-column label="性别" width="auto" align="center">
										<template slot-scope="scope">
											{{ stu_health.student.StuGender == 0 ? '女' : '男' }}
										</template>
									</el-table-column>
									<el-table-column prop="date" label="更新日期" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="age" label="年龄" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="stu_height" label="身高(cm)" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="stu_height_change" label="距上次身高变化(cm)" width="auto"
										align="center">
										
										<template slot-scope="scope">
											{{ scope.row.stu_height_change }}
											<i v-if="scope.row.stu_height_change>0" class="el-icon-top" style="color:green" />
											<i v-if="scope.row.stu_height_change<0" class="el-icon-bottom" style="color:red" />
										</template>
									</el-table-column>
									<el-table-column prop="stu_weight" label="体重(kg)" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="stu_weight_change" label="距上次体重变化(kg)" width="auto"
										align="center">
										<template slot-scope="scope">
											{{ scope.row.stu_weight_change }}
											<i v-if="scope.row.stu_weight_change>0" class="el-icon-top" style="color:green" />
											<i v-if="scope.row.stu_weight_change<0" class="el-icon-bottom" style="color:red" />
										</template>
									</el-table-column>
									<el-table-column prop="bmi" label="MBI" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="assess" label="评估BMI" width="auto" align="center">
										<template slot-scope="scope">
											<div :style="{color:scope.row.assess=='正常'?'yellowgreen':'red'}">{{scope.row.assess}}</div>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane label="运动记录" name="five">
								<el-table :data="stu_motionInfo.List" v-loading="loading" style="margin-top: 31px;">
									<el-table-column prop="stu_id" label="学号" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="walk" label="步数" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="heartbeat" label="平均心率" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="finish_calorie" label="消耗卡路里" width="auto" align="center">
									</el-table-column>
									<el-table-column prop="speed" label="目标进度" width="auto" align="center">
										<template slot-scope="scope">{{ scope.row.speed }}%</template>
									</el-table-column>
									<el-table-column prop="status" label="是否达标" width="auto" align="center">
										<template slot-scope="scope">
											<div v-if="scope.row.status == 1" style="color:yellowgreen">已达标</div>
											<div v-if="scope.row.status == 2" style="color:red">未达标</div>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
						</el-tabs>
					</template>
				</div>

				<el-dialog :visible.sync="dialogVisible" title='导出数据' width="500px">
					<div>
						<el-form label-width="80px" class="demo-ruleForm">
							<el-form-item label="时间范围">
								<el-date-picker v-model="daterange" value-format="yyyy-MM-dd" type="daterange"
									:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
									end-placeholder="结束日期">
								</el-date-picker>
							</el-form-item>
						</el-form>
						<span slot="footer" class="dialog-footer">
							<el-button @click="dialogVisible = false">取 消</el-button>
							<el-button type="primary" @click="doExport">确 定</el-button>
						</span>
					</div>
				</el-dialog>
				<el-dialog :visible.sync="healthDialogVisible" width="31vw">
					<template slot="title">
						<div class='' style="font-size: 26px;">添加健康信息</div>
					</template>
					<table style="margin-top: -20px;" class="addta">
						<tr>
							<td class="tit">身高</td>
							<td>
								<el-input v-model="stu_height" placeholder="请输入学生身高" style="width: 20vw;"></el-input>
							</td>
						</tr>
						<tr>
							<td class="tit">体重</td>
							<td>
								<el-input v-model="stu_weight" placeholder="请输入学生体重" style="width: 20vw;"></el-input>
							</td>
						</tr>
					</table>
					<p style="width: 100%;">
						<el-button type="primary" @click="onAddHealth"
							style="display: inline-block;margin-right: 40px;">添 加
						</el-button>
						<el-button type="primary" @click="onCancelHealth" style="display: inline-block;">取 消</el-button>
					</p>

				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {
	accessList,
	teastuinfo,
	authGuaList,
	handStuOne,
	motionInfoOne
} from '@/api/TeacherApi.js';
import {
	mapGetters
} from "vuex";
import {
	healthStuEyeSearch
} from '@/api/agentApi.js';
import {
	excelDaochueyeDays,
	excelDaochueyeWord,
	handStu,
	motionInfo,
	setBasic
} from '@/api/SchoolApi.js'
import { Message } from 'element-ui';
export default {
	computed: {
		...mapGetters(["Status"]),
	},
	data() {
		return {
			stu_height: '',
			stu_weight: '',
			healthDialogVisible: false,
			stu_motionInfo: null,
			stu_health: null,
			listCount: 0,
			dialogVisible: false,
			stuUserID: '',
			dstatus: false,
			stuData: [],
			activeName: 'first',
			guaData: [],
			loading: false,
			guaStart: 0,
			cnNum: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五'],
			cityid: '',
			stu_list: [],
			daterange: [],
			daterange1: null,
			list: [],
			pickerOptions: {
				// 设置不能选择的日期
				onPick: ({
					maxDate,
					minDate
				}) => {
					this.choiceDate0 = minDate.getTime();
					if (maxDate) {
						this.choiceDate0 = '';
					}
				},
				disabledDate: (time) => {
					let choiceDateTime = new Date(this.choiceDate0).getTime();
					const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
					const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
					const min = minTime;
					const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
					const max = newDate < maxTime ? newDate : maxTime;
					//如果已经选中一个日期 则 返回 该日期前后一个月时间可选
					if (this.choiceDate0) {
						return time.getTime() < min || time.getTime() > max;
					}
					//若一个日期也没选中 则 返回 当前日期以前日期可选
					return time.getTime() > newDate;
				}
			},
		}
	},
	created() {
		if (localStorage.getItem('tabstu') == 2) {
			this.activeName = 'second',
				this.dstatus = true,
				localStorage.setItem('tabstu', 1)
		}
	},
	mounted() {
		this.stuUserID = this.$route.params.StuID // 给学生ID赋值 通过上个页面$route传递过来的
		this.StuInfo(); //获取学生详细信息
		this.Gualist(1); //获取家长列表·
		this.health_detail();
		this.getAccessList()
		this.getHandStu()
		this.getMotionInfo()
		// this.gradeListvue() // 获取年级信息
	},
	methods: {
		onAddHealth() {
			this.loading = true
			setBasic({
				stu_height: this.stu_height,
				stu_weight: this.stu_weight,
				student_id: this.stuUserID
			}).then(res => {
				this.loading = false
				if (res.status.code != 1) {
					Message.error(res.status.msg)
					return
				}
				this.getHandStu()
				this.healthDialogVisible = false
				this.stu_height = ''
				this.stu_weight = ''
			}).catch(err => {
				console.log(err)
			})
		},
		onCancelHealth() {
			this.healthDialogVisible = false
		},
		openAddDialog() {
			this.healthDialogVisible = true
		},
		getMotionInfo() {
			this.loading = true
			const data = {
				student_id: this.stuUserID, // 学生ID
				page: 1,
				limit: 999
			}
			if (this.Status == 'teacher') {

				motionInfoOne(data).then(res => {
					this.loading = false
					this.stu_motionInfo = res.data
				}).catch(err => {
					console.log(err)
				})
			}
			if (this.Status == 'school') {

				motionInfo(data).then(res => {
					this.loading = false
					this.stu_motionInfo = res.data
				}).catch(err => {
					console.log(err)
				})
			}
		},
		getHandStu() {
			this.loading = true
			const data = {
				student_id: this.stuUserID, // 学生ID
			}
			
			if (this.Status == 'teacher') {
				handStuOne(data).then(res => {
				this.loading = false
				this.stu_health = res.data
			}).catch(err => {
				console.log(err)
			})
			}
			if (this.Status == 'school') {
			handStu(data).then(res => {
				this.loading = false
				this.stu_health = res.data
			}).catch(err => {
				console.log(err)
			})
		}
		},
		onChangePage(e) {
			this.getAccessList(e)
		},
		onChangeDate(e) {
			this.getAccessList(1)
		},
		handleClick(tab, event) {
			//console.log(tab.name);
			if (tab.name == 'first') {
				this.dstatus = false
			} else {
				this.dstatus = true
			}
		},

		indexMethod(index) { // 筛选性别
			return '家长' + this.cnNum[index]

		},
		//视力详情
		health_detail() {
			this.loading = true
			const data = {
				studentid: this.stuUserID, // 学生ID
			}
			healthStuEyeSearch(data).then(res => {
				this.loading = false
				this.stu_list = res.data.list;
				this.city = res.data.city;
			}).catch(err => {
				console.log(err)
			})
		},
		detail(type) { // 点击详情
			localStorage.setItem('Route', 'health') // 存跳转标识
			localStorage.setItem('tabstu', 2) // 存跳转标识
			this.$router.push({
				name: 'shealth_history',
				params: {
					student_id: this.stuUserID,
					type: type,
					city: this.cityid,
					stuname: this.stuData.StuName,
				}
			})
		},
		StuInfo() {
			const data = {
				stuUserID: this.stuUserID, //
			}
			teastuinfo(data).then(res => {
				this.stuData = res.data; //数据
				this.cityid = res.data.City; //数据
			}).catch(err => {
				console.log(err)
			})
		},
		toStulist() {
			this.$router.push({ //页面跳转
				name: 'studentList', //往这个页面跳转
				params: { //跳转传递的参数
				}
			})
		},
		getAccessList(currpage = 1) {
			this.loading = true;

			const startArr = this.daterange1 ? this.daterange1[0].split('-') : []
			const endArr = this.daterange1 ? this.daterange1[1].split('-') : []

			const data = {
				studentid: this.$route.params.StuID,
				page: currpage, // 当前页数
				limit: 5, //	每页条数
				start: startArr.join(''),
				end: endArr.join('')
			}
			accessList(data).then(res => {
				this.loading = false;
				this.list = res.data.List; //数据
				this.listCount = res.data.CountPage
				console.log(this.listCount)
			}).catch(err => {
				console.log(err, '错误信息')
			})
		},
		Gualist(currpage) { //获取家长实名列表
			this.loading = true;
			const data = {
				stuUserID: this.$route.params.StuID,
				page: currpage, // 当前页数
				limit: 10 //	每页条数
			}
			authGuaList(data).then(res => {
				this.loading = false;
				this.guaData = res.data.List; //数据
			}).catch(err => {
				console.log(err, '错误信息')
			})
		},
		upStuDetail() {
			this.$router.push({ //页面跳转
				name: 'studeupdate', //往这个页面跳转
				params: { //跳转传递的参数
					StuID: this.stuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
				}
			})
		},
		upStuEyeDetail() {
			localStorage.setItem('tabstu', 2) // 存跳转标识

			this.$router.push({ //页面跳转
				name: 'studeupdateeye', //往这个页面跳转
				params: { //跳转传递的参数
					StuID: this.stuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
				}
			})
		},
		reset() { //重置按钮
			this.classList = [];
			this.gradeid = '';
			this.classid = '';
			this.stuname = '';
			this.SearchName = '';
			this.gradeidsearch = '';
			this.classidsearch = '';
			this.StudentList(this.currpage);
		},
		upStuHealth() { //更新健康信息
			// this.classList = [];
			// this.gradeid = '';
			// this.classid = '';
			// this.stuname = '';
			// this.SearchName     = '';
			// this.gradeidsearch  = '';
			// this.classidsearch  = '';
			// this.StudentList(this.currpage);
		},
		doExport() {
			if (this.daterange && this.daterange.length == 0) {
				this.$message.error('请选择时间范围')
				return false
			} else if (this.daterange == null) {
				this.$message.error('请选择时间范围')
				return false
			}

			const data = {
				studentid: this.stuUserID, // 学生ID
				city: this.cityid,
				startdate: this.daterange[0],
				enddate: this.daterange[1]
			}
			excelDaochueyeDays(data).then(res => {
				this.loading = false
				if (res.status.code == 1) {
					// window.open('http://sygp.shiyuntech.net' + res.data)
					window.open(this.$url + res.data)
					this.dialogVisible = false
				} else {
					this.$message.error(res.status.msg)
				}
			}).catch(err => {
				console.log(err)
			})
		},
		doExportWord() {
			this.loading = true
			const data = {
				studentid: this.stuUserID, // 学生ID
				city: this.cityid
			}

			excelDaochueyeWord(data).then(res => {
				this.loading = false
				if (res.status.code == 1) {
					// window.open('http://sygp.shiyuntech.net' + res.data)
					window.open(this.$url + res.data)
					this.dialogVisible = false
				} else {
					this.$message.error(res.status.msg)
				}
			}).catch(err => {
				console.log(err)
			})
		},


	}

}
</script>

<style lang="scss" scoped>
//新样式
.el-dropdown-link {
	cursor: pointer;
	color: #409EFF;
}

.el-icon-arrow-down {
	font-size: 12px;
}

.stutitle {
	font-size: 30px !important;
	margin-right: 55% !important;
}

.tt {
	height: 40px;
	line-height: 40px;
	font-size: 22px;
	font-weight: 500;
}

.tabtit {
	width: 100px;
	display: inline-block;
	text-align: right;
}

tr {
	line-height: 45px;
}

#body {
	background: #f0f2f5;
}

.fatherBox {
	width: 98%;
	// height: 800px;
	display: flex;
	background: white;
	margin: 0 auto;
	flex-direction: column;
	justify-content: center;

	.headerBox {
		display: flex;
		height: 52px;
		width: 98%;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid #e9e9e9;

		p {
			font-size: 16px;
			font-family: 萍方-简;
			font-weight: normal;
			color: #000000;
			margin-left: 32px;
		}

		.btn {
			display: flex;
			align-items: center;
			width: 109px;
			height: 32px;
			opacity: 1;
			border-radius: 2px;
		}
	}

	.del {
		float: left,
	}

	.query {
		width: 98%;
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		padding-left: 32px;

		.queryLeft {
			width: 75%;
			display: flex;
			flex-wrap: wrap;

			div {
				width: 300px;
				line-height: 50px;
				text-align: left;

				.input,
				.select {
					width: 60%;
				}
			}
		}
	}

	/deep/.el-tabs__header {
		width: fit-content;
	}

}
</style>
class="el-tabs__header is-top"

style=" width: fit-content;
"